
import { defineComponent, ref } from 'vue';

import DocumentViewModel from '@/Model/DocumentViewModel';
import useInspection from '@/store/useInspection';
import toDateString from '@/utillities/toDateString';
import { appState } from '@/store';
export default defineComponent({
  components: {},
  props: {
    inspectionId: { type: Number, reqried: true }
  },
  setup(props) {
    const { getDocuments, getDocument } = useInspection();
    const documents = ref<Array<DocumentViewModel>>();
    if (props.inspectionId) {
      getDocuments(props.inspectionId).then(
        (result: Array<DocumentViewModel>) => {
          documents.value = result;
        }
      );
    }
    const download = (filename: string, id: number) => {
      console.log(filename);
      getDocument(id)
        .then((result: Blob) => {
          const blob = new Blob([result], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error: string[]) => {
          appState.raiseError(error);
        });
    };

    return { documents, toDateString, download };
  }
});
