<template>
  <tr v-if="log">
    <td class="text-start">{{ toDateString(log.created) }}</td>
    <td class="text-start">
      {{ log.message }}
      <a
        v-if="log.note && log.note.length > 0"
        tabindex="0"
        class=""
        role="button"
        ref="popopRef"
        data-bs-toggle="popover"
        placement="left"
        data-bs-trigger="focus"
        title="Note"
        :data-bs-content="log.note"
      >
        <i class="bi bi-card-text"></i>
      </a>
    </td>
    <td class="text-start">{{ log.createdBy }}</td>
  </tr>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, computed, onMounted } from 'vue';
import InspectionLogViewModel from '@/Model/InspectionLogViewModel';
import toDateString from '@/utillities/toDateString';
import { Popover } from 'bootstrap';

export default defineComponent({
  Name: 'InspectionLogRow',
  props: {
    log: {
      type: Object as PropType<InspectionLogViewModel>,
      reqried: true
    }
  },
  setup(props) {
    const hasNote = computed<boolean>(() =>
      props.log && props.log.note && props.log.note.length > 0 ? true : false
    );
    const popopRef = ref();

    onMounted(() => {
      if (popopRef.value) {
        var popover = new Popover(popopRef.value, {
          placement: 'left',
          container: '#logs-tab'
        });
      }
    });
    return { popopRef, hasNote, toDateString };
  }
});
</script>
<style lang="scss" scoped>
.popover {
  max-width: 600px;
  width: 100%;
}
</style>