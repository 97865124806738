
import { defineComponent, PropType, ref, computed, onMounted } from 'vue';
import InspectionLogViewModel from '@/Model/InspectionLogViewModel';
import toDateString from '@/utillities/toDateString';
import { Popover } from 'bootstrap';

export default defineComponent({
  Name: 'InspectionLogRow',
  props: {
    log: {
      type: Object as PropType<InspectionLogViewModel>,
      reqried: true
    }
  },
  setup(props) {
    const hasNote = computed<boolean>(() =>
      props.log && props.log.note && props.log.note.length > 0 ? true : false
    );
    const popopRef = ref();

    onMounted(() => {
      if (popopRef.value) {
        var popover = new Popover(popopRef.value, {
          placement: 'left',
          container: '#logs-tab'
        });
      }
    });
    return { popopRef, hasNote, toDateString };
  }
});
