<template>
  <span v-if="!logs || (logs && logs.length === 0)">
    Ingen hændelser på sagen
  </span>
  <table v-else class="table table-hover table-sm">
    <thead>
      <tr>
        <th class="text-start">Dato</th>
        <th class="text-start">Hændelse</th>
        <th class="text-start">Bruger</th>
      </tr>
    </thead>
    <tbody>
      <LogRow v-for="log in logs" :key="log.id" :log="log"></LogRow>
    </tbody>
  </table>
</template>
<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue';
import useInspectionLogs from '@/store/useInspectionLogs';
export default defineComponent({
  components: {
    LogRow: defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "inspection" */ './InspectionLogRow.vue')
    )
  },
  Name: 'InspectionLogs',
  props: {
    inspectionId: { type: Number, reqried: true }
  },
  setup(props) {
    const id = props.inspectionId ?? 0;
    const { logs } = useInspectionLogs(id);

    return { logs };
  }
});
</script>
