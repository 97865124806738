import InspectionLogViewModel from '../Model/InspectionLogViewModel';
import useAuthToken from '@/utillities/useAuthToken';
import axios, { AxiosResponse } from 'axios';
import IComandResultTyped from '@/Model/IComandResultTyped';
import { ref } from 'vue';

export default (id: number): any => {
  const logs = ref<Array<InspectionLogViewModel>>();
  const getlogs = async (): Promise<void> => {
    await useAuthToken();
    return new Promise<void>((resolve, reject) => {
      axios
        .get(`/api/Inspection/${id}/logs`)
        .then(
          (
            response: AxiosResponse<
              IComandResultTyped<Array<InspectionLogViewModel>>
            >
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                logs.value = response.data.data;
                resolve();
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  getlogs();
  return { logs };
};
