
import { defineAsyncComponent, defineComponent } from 'vue';
import useInspectionLogs from '@/store/useInspectionLogs';
export default defineComponent({
  components: {
    LogRow: defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "inspection" */ './InspectionLogRow.vue')
    )
  },
  Name: 'InspectionLogs',
  props: {
    inspectionId: { type: Number, reqried: true }
  },
  setup(props) {
    const id = props.inspectionId ?? 0;
    const { logs } = useInspectionLogs(id);

    return { logs };
  }
});
