<template>
  <div>
    <span
      class="text-sm-left"
      :class="{
        'text-danger': status === 0,
        'text-warning': status === 1 || status === 5,
        'text-success': status === 2 || status === 4
      }"
    >
      <span class="bi" :class="icon">
        {{ text }}
      </span>
    </span>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';
import toDateString from '@/utillities/toDateString';

export default defineComponent({
  props: {
    status: { type: Number, default: 3 },
    experationDate: { type: [Date, String], default: null }
  },
  setup(props) {
    const icon = computed<string>(() => {
      switch (props.status) {
        case 0:
          return 'bi-dash-circle-fill';
        case 1:
        case 5:
          return 'bi-exclamation-circle-fill';
        case 2:
        case 4:
          return 'bi-check-circle-fill';
      }
      return '';
    });
    const text = computed<string>(() => {
      const date = props.experationDate;
      switch (props.status) {
        case 0:
          return ' Ikke godkendt';
        case 1:
        case 5:
          return date
            ? ` Betinget godkendt til ${toDateString(date)}`
            : ' Betinget godkendt';
        case 2:
          return ' Godkendt';
        case 4:
          return ' Godkendt med C-fejl';
      }
      return '';
    });
    return {
      icon,
      text
    };
  }
});
</script>
